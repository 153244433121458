<template>
  <div class="bind">
    <van-form @submit="onSubmit">
      <van-field v-model="form.mobile"
                 name="手机号"
                 label="手机号"
                 placeholder="请输入手机号"
                 :rules="[{ required: true }]" />
      <van-field v-model="form.code"
                 name="验证码"
                 label="验证码"
                 placeholder="请输入验证码"
                 :rules="[{ required: true }]">
        <template #button>
          <van-button size="small"
                      type="info"
                      @click="send"
                      :disabled="str != '发送验证码'">{{ str }}</van-button>
        </template>
      </van-field>
      <!-- <div style="margin: 16px">
                <van-button round block type="info" native-type="submit">绑 定</van-button>
            </div>-->
    </van-form>
  </div>
</template>

<script>
export default {
  props: {

  },
  data () {
    return {
      form: {},
      str: "发送验证码"
    };
  },
  computed: {

  },
  created () {

  },
  mounted () {

  },
  watch: {

  },
  methods: {
    onSubmit () {
      // this.$api.get(`/jnapi/sms/valid?mobile=${this.form.mobile}&code=${this.form.code}`).then(res => {
      //   if (res.data) {
      // this.$api.post('/jnapi/wechat/save', { mobile: this.form.mobile }).then(res => {
      //   if (res.data) {
      //     this.$toast('绑定成功');
      //     this.$cookies.set("mobile", this.form.mobile || '');
      //     this.$emit('success')
      //   }
      // })
      //   }
      // })
      this.$api.post('/api/crm/businessCard/bind', {
        mobile: this.form.mobile,
        verifyCode: this.form.code,
        openId: this.$cookies.get('openId')
      }).then(res => {
        if (res.code === 'C0000') {
          this.$toast('绑定成功');
          this.$cookies.set("mobile", this.form.mobile || '');
          this.$emit('success')
        } else {
          this.$toast(res.message)
        }
      })
    },
    send () {
      if (!this.form.mobile) {
        this.$toast('请输入手机号');
        return
      }
      this.$api.get(`/api/p/message/mobile/sendAuthMobileCode?mobile=${this.form.mobile}`).then(res => {
        if (res.code === 'C0000') {
          this.$toast('验证码发送成功');
          this.str = 60
          let timer = setInterval(() => {
            if (this.str == 1) {
              this.str = '发送验证码'
              clearInterval(timer)
              return false
            }
            this.str = this.str - 1
          }, 1000)
        }
      })
    }
  },
  components: {

  },
};
</script>

<style scoped lang="less">
.bind {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
  padding-top: 40px;
  position: relative;
}
.close {
  position: absolute;
  font-size: 70px;
  right: -35px;
  top: -35px;
  text-align: right;
  color: red;
  z-index: 99999;
}
.bing_btn {
  width: 80%;
  bottom: 150px;
  margin: 0 auto;
}
</style>
