<template>
  <div class="flow">
    <div class="top_img">
      <img src=""
           alt />
    </div>
    <div class="low_content">
      <div class="card in">
        <img class="card_img"
             src="../assets/images/flow_purchase.png"
             alt="">
        <div class="title">
          我需要流量
          <img src="../assets/images/flow_in.png"
               alt="">
        </div>
        <div class="tags">
          <span>内贸</span>
          <span>外贸</span>
        </div>
        <div class="amount">
          <span>$1.00</span>
          3-5分
        </div>

        <div class="btn" @click="into(9658001230921728)">进入</div>
      </div>
      <div class="card out">
        <img class="card_img"
             src="../assets/images/flow_sale.png"
             alt="">
        <div class="title">
          我提供流量
          <img src="../assets/images/flow_out.png"
               alt="">
        </div>

        <div class="tags">
          <span>内贸</span>
          <span>外贸</span>
        </div>
        <div class="amount">
          <span>$1.00</span>
          3-4分
        </div>

        <div class="btn" @click="into(9658002480824320)">进入</div>
      </div>

      <div class="products">
        <div class="title">海量的流量供需资源池</div>
        <div class="item-list">
          <div class="item"
               style="width: 33.3%"
               v-for="(item, i) in system"
               :key="i"
               @click="openPage(item)">
            <img :src="item.url"
                 alt="">
            <p>{{item.name}}</p>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {

  },
  data () {
    return {
      system: [
        { name: '供应链金融公司', url: require('../assets/images/flow_icon1.png') },
        { name: '供应链服务公司', url: require('../assets/images/flow_icon2.png') },
        { name: '国企供应链公司', url: require('../assets/images/flow_icon3.png') },
        { name: '进出口贸易公司', url: require('../assets/images/flow_icon4.png') },
        { name: '报关公司', url: require('../assets/images/flow_icon5.png') },
        { name: '商贸公司', url: require('../assets/images/flow_icon6.png') }
      ]
    };
  },
  computed: {

  },
  created () {

  },
  mounted () {

  },
  watch: {

  },
  methods: {
      into (id) {
          // this.$router.push({ path: "/articleInfo", query: {id} });
          this.$router.push(
              `/details?id=${localStorage.getItem("ladingPageId") || 0
                  }&businessCardId=${localStorage.getItem("businessCardId")
                  }&channelId=${localStorage.getItem("channelId") || 0
                  }&articleId=${id}`
          )
      }
  },
  components: {

  },
};
</script>

<style scoped lang="less">
.flow {
  padding-bottom: 50px;
  background: #f5fafd;
  .top_img {
    width: 100%;
    height: 340px;
    background: url(../assets/images/flow_bg.png);
    background-size: 100% 100%;
  }
  .low_content {
    padding: 0 30px;
    margin-top: -186px;
    box-sizing: border-box;
  }
  .products {
    box-sizing: border-box;
    background: #fdfcff;
    box-shadow: 3px 3px 13px 0px rgba(160, 160, 160, 0.13);
    border-radius: 20px;
    padding: 30px 40px;
    position: relative;
    margin-top: 30px;
    .title {
      font-size: 36px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      text-align: center;
      margin: 16rpx 0;
      &:before {
        content: "——";
        color: #dde2ee;
        padding-right: 10px;
        font-weight: 400;
      }
      &:after {
        content: "——";
        color: #dde2ee;
        padding-left: 10px;
        font-weight: 400;
      }
    }
    .item-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      .item {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        text-align: center;
        margin-top: 45px;
        img {
          width: 48px;
          height: 48px;
          margin-bottom: 24px;
        }
      }
    }
  }
  .card {
    box-sizing: border-box;
    width: 100%;
    height: 300px;
    border: 1px solid #ffffff;
    border-radius: 20px;
    padding: 30px 40px;
    position: relative;
    .title {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      img {
        width: 70px;
        height: 70px;
        margin-left: 10px;
        vertical-align: -0.1em;
      }
    }
    .card_img {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 88%;
    }
    .tags {
      margin-top: 40px;
      span {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        padding: 6px 10px;
        background: #f25023;
        border-radius: 4px;
        margin-right: 20px;
      }
    }
    .amount {
      margin-top: 40px;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
      span {
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #f25023;
        line-height: 28px;
        padding-right: 10px;
      }
    }
    .btn {
      position: absolute;
      right: 40px;
      top: 122px;
      width: 120px;
      height: 56px;
      background: #3874f6;
      border-radius: 28px;
      text-align: center;
      line-height: 56px;
      font-size: 28px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .in {
    background: linear-gradient(0deg, #cad8ff 0%, #f4faff 100%);
    box-shadow: 3px 3px 13px 0px rgba(160, 160, 160, 0.13);
    // background-image: url(../assets/images/flow_purchase.png);
    // background-size: auto 88%;
    // background-position: 100% 100%;
    // background-repeat: no-repeat;
  }
  .out {
    margin-top: 30px;
    background: linear-gradient(0deg, #ffddb8 0%, #fffaf4 100%);
    box-shadow: 3px 3px 13px 0px rgba(160, 160, 160, 0.13);
    // background: url(../assets/images/flow_sale.png);
    // background-size: auto 88%;
    // background-position: 100% 100%;
    // background-repeat: no-repeat;
  }
}
</style>
