<template>
  <div class="cardDetails">
    <div class="msg">
      <img :src="form.businessCard.companyLogo"
           class="logo" />
      <div class="head_img">
        <img :src="form.businessCard.headPic" />
      </div>
      <p class="name">{{ form.businessCard.name }}</p>
      <div class="post">{{ form.businessCard.post }}</div>
      <div class="line"></div>
      <div class="p">
        <p class="p1">{{ form.businessCard.company }}</p>
        <p class="p1">{{ form.businessCard.email }}</p>
        <p class="p1">{{ $getPhone(form.businessCard.mobile) }}</p>
        <p class="p1">{{ form.businessCard.www }}</p>
        <p class="p1">{{ form.businessCard.address }}</p>
      </div>
      <img :src="form.businessCard.wechatQrCode"
           class="qrcode" />
    </div>
  </div>
</template>

<script>
import mixin from "../utils/share";

export default {
  mixins: [mixin],
  props: {},
  data () {
    return {};
  },
  computed: {},
  created () { },
  mounted () { },
  watch: {},
  methods: {},
  components: {},
};
</script>

<style scoped lang="less">
.cardDetails {
  height: 100vh;
  background: url("../assets/images/组 3.png");
  background-size: 100% 100%;
  overflow: hidden;
  .qrcode {
    width: 250px;
    height: 250px;
    display: block;
    margin: 0 auto;
    margin-top: 60px;
  }
  .msg {
    width: 686px;
    height: 1272px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: 30px;
    position: relative;
    overflow: hidden;
    .p {
      margin-top: 58px;
      padding: 0 36px;
      .p1 {
        text-align: right;
        line-height: 60px;
        width: 613px;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
    }
    .name {
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #111111;
      text-align: center;
      padding-top: 24px;
      margin-bottom: 25px;
    }
    .post {
      width: 140px;
      height: 46px;
      border: 1px solid #3490e7;
      border-radius: 23px;
      line-height: 46px;
      text-align: center;
      margin: 0 auto;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2d85e7;
      margin-bottom: 40px;
    }
    .logo {
      width: 90px;
      height: 90px;
      position: absolute;
      top: 30px;
      left: 42px;
    }
    .head_img {
      width: 312px;
      margin: 0 auto;
      height: 312px;
      margin-top: 104px;
      background: url("../assets/images/椭圆 1.png");
      background-size: 100% 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 270px;
        height: 270px;
        border-radius: 50%;
      }
    }
  }
  .line {
    width: 613px;
    height: 0px;
    border-top: 1px dashed #3490e7;
    margin: 0 auto;
  }
}
</style>
