import { render, staticRenderFns } from "./cardDetails.vue?vue&type=template&id=6aa0cc8c&scoped=true&"
import script from "./cardDetails.vue?vue&type=script&lang=js&"
export * from "./cardDetails.vue?vue&type=script&lang=js&"
import style0 from "./cardDetails.vue?vue&type=style&index=0&id=6aa0cc8c&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aa0cc8c",
  null
  
)

export default component.exports