<template>
  <div>
    <van-overlay :show="showShare"
                 @click="showShare = false">
      <img src="../assets/images/share.png"
           alt
           class="share_img" />
    </van-overlay>

    <div class="top_bg">
      <div class="options"
           v-if="!mobile">
        <div class="title">专为企业打造的数字名片</div>
        <div class="option_btn">
          <div class="cert"
               @click="authentication">立即认证</div>
          <div class="trail">免费试用</div>
        </div>
      </div>
      <div class="my_card"
           v-else-if="myCards.length">
        <div class="card_item"
             @click="viewCard(myCards[currCardIndex].id)">
          <div class="head">
            <img :src="myCards[currCardIndex].headPic"
                 alt
                 style="width: 70px;height: 70px"
                 class="head_img" />
            <div class="pp">
              <p class="p1">{{myCards[currCardIndex].name}}
                <img v-if="myCards[currCardIndex].openId"
                     class="cert_icon"
                     src="../assets/images/v.png"
                     alt="">
              </p>
              <p class="p2">{{myCards[currCardIndex].post}}</p>
              <p class="p3">{{myCards[currCardIndex].company}}</p>
              <!-- <p class="p3">{{myCards[currCardIndex].company}}</p> -->
            </div>
            <img :src="myCards[currCardIndex].companyLogo"
                 alt
                 class="company_img" />
          </div>
          <div class="item_info">
            <img src="../assets/images/图层 10.png"
                 alt />
            <span class="span2">{{myCards[currCardIndex].mobile}}</span>
          </div>
          <div class="item_info">
            <img src="../assets/images/图层 11.png"
                 alt />
            <span class="span2">{{myCards[currCardIndex].email}}</span>
          </div>
          <div class="item_info">
            <img src="../assets/images/图层 16.png"
                 alt />
            <span class="span2">{{myCards[currCardIndex].www}}</span>
          </div>
          <div class="item_info">
            <img src="../assets/images/图层 12 拷贝.png"
                 alt />
            <span class="span2">{{myCards[currCardIndex].address}}</span>
          </div>
        </div>

        <div class="switch"
             v-if="myCards.length > 1"
             @click.stop="showSwitchCards = true">
          <van-icon name="exchange" />
          <!-- <p>切换</p> -->
        </div>
      </div>
      <div class="options"
           v-else>
        <div class="title">专为企业打造的数字名片</div>

        <div class="manager_card">
          <img src="http://scp.zhongpukeji.cn/f/image/1625280089818.png"
               alt=""
               class="manager_pic">
          专属客户经理<span @click="viewCard(1149152077873152)">@张晓宇</span>，立即联系添加企业名片
          <van-icon name="arrow" />
        </div>
      </div>

      <div class="mine_content">
        <div class="box"
             v-if="mobile">
          <!-- <div class="content_name"
               @click="showStatistic = !showStatistic">我的名片分析
            <van-icon :name="showStatistic ? 'arrow-up' : 'arrow-down'" />
          </div> -->
          <div class="tabs">
            <div v-for="(item, i) in ['今天', '本月', '今年', '累计']"
                 :key="i"
                 @click="changeScope(i)"
                 :class="{'tab_active': activeScope === i}"
                 class="tab_item">{{item}}</div>
          </div>
          <div class="statistic"
               v-if="showStatistic">
            <div class="statistic_item">
              <div class="number">{{myStatistics['浏览名片'] || 0}}</div>
              <div class="name">点击量(次)</div>
            </div>
            <div class="statistic_item">
              <div class="number">{{myStatistics['分享名片'] || 0}}</div>
              <div class="name">被转发(次)</div>
            </div>
            <div class="statistic_item">
              <div class="number">{{myStatistics['发送名片'] || 0}}</div>
              <div class="name">我发出(次)</div>
            </div>
          </div>
        </div>

        <div class="box">
          <div class="content_name"
               @click="showCards = !showCards">我的名片夹
            <span class="total_number">{{favoriteCards.length}}</span>
            <van-icon :name="showCards ? 'arrow-up' : 'arrow-down'" />
          </div>
          <div class="no_data"
               v-if="showCards && !favoriteCards.length">暂无数据</div>
          <div class="card_list"
               v-if="showCards && favoriteCards.length">
            <div class="card_item"
                 v-for="(card, i) in favoriteCards"
                 :key="card.id"
                 @click="viewCard(card.businessCardId)">
              <div class="head">
                <img :src="card.businessCard ? card.businessCard.headPic : ''"
                     alt
                     style="width: 78px;height: 78px"
                     class="head_img" />
                <div class="pp">
                  <p class="p1">{{card.businessCard ? card.businessCard.name : ''}}
                    <img v-if="card.businessCard && card.businessCard.openId"
                         class="cert_icon"
                         src="../assets/images/v.png"
                         alt="">
                  </p>
                  <p class="p2">{{card.businessCard ? card.businessCard.post : ''}}</p>
                  <p class="p3">{{card.businessCard ? card.businessCard.company : ''}}</p>
                  <div class="item_info p3"
                       style="margin-top: 0">
                    <img src="../assets/images/图层 10.png"
                         alt />
                    <span class="span2">{{card.businessCard ? card.businessCard.mobile : ''}}</span>
                  </div>
                </div>
                <div class="popover_box">
                  <van-popover v-model="showPopover[i]"
                               :actions="actions"
                               @select="onSelect($event, card)"
                               @click.native.stop="">
                    <template #reference>
                      <van-icon name="ellipsis"
                                @click.native="showCardOption(i)" />
                    </template>
                  </van-popover>
                </div>

                <!--<img :src="card.businessCard ? card.businessCard.companyLogo : ''"-->
                <!--alt-->
                <!--class="company_img" />-->
              </div>

            </div>
          </div>
        </div>

        <div class="box">
          <div class="content_name">最近访客
            <!-- @click="showUser = !showUser" -->
            <!--<span class="total_number">{{visitorCards.length}}</span>-->
            <!-- <van-icon :name="showUser ? 'arrow-up' : 'arrow-down'" /> -->
          </div>
          <div class="no_data"
               v-if="showUser && !visitorCards.length">暂无数据</div>
          <div class="user_list"
               v-if="showUser && visitorCards.length">
            <div class="user_item"
                 v-for="(item, i) in visitorCards"
                 :key="i"
                 @click="toUserCard(item)">
              <div class="head">
                <img :src="item.wechatUser && item.wechatUser.headpic ? item.wechatUser.headpic : defaultHead"
                     alt
                     class="head_img" />
                <div class="pp">
                  <p class="p1"
                     style="font-size: 14px">{{item.wechatUser && item.wechatUser.nickname ? item.wechatUser.nickname : '客户害羞，还没告诉我'}}
                    <img v-if="item.role === '企业用户'"
                         class="cert_icon"
                         src="../assets/images/v.png"
                         alt="">
                    <span class="date">{{item.createTime || ''}}</span>
                  </p>
                  <p class="p2">{{item.city || ''}}</p>
                  <p class="p3">
                    <!-- <span>{{item.actionDesc || ''}}，</span> -->
                    <span v-if="item.fromWechatUser && item.fromWechatUser.nickname">{{`由${item.fromWechatUser ? item.fromWechatUser.nickname || '' : ''}分享`}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <qrcode v-model="qrshow"
            :src="qrcode"></qrcode>
    <van-overlay :show="show"
                 @click="show = false">
      <div class="wrapper"
           @click.stop>
        <img :src="qrcode"
             alt=""
             class="img_img" />
        <img src="../assets/images/关闭.png"
             alt=""
             class="close"
             @click="show = false" />
      </div>
    </van-overlay>

    <van-dialog v-model="dialogshow"
                title="绑定手机号"
                show-cancel-button
                confirmButtonColor="#1989fa"
                @confirm="$refs.bindTel.onSubmit()">
      <bindTel @success="
          dialogshow = false;
          mobile = true;
          getMyCards()
        "
               ref="bindTel"></bindTel>
    </van-dialog>

    <van-action-sheet v-model="showSwitchCards"
                      title="名片切换">
      <div class="my_card_list">
        <div class="card_item"
             v-for="(card, i) in myCards"
             :key="card.id"
             :class="{'active_card': i === currCardIndex}"
             @click="viewCard(card.id)">
          <div class="head">
            <img :src="card.headPic"
                 alt
                 style="width: 78px;height: 78px"
                 class="head_img" />
            <div class="pp">
              <p class="p1">{{card.name}}
                <img v-if="card.openId"
                     class="cert_icon"
                     src="../assets/images/v.png"
                     alt="">
              </p>
              <p class="p2">{{card.post}}</p>
              <p class="p3">{{card.company}}</p>
              <div class="item_info p3">
                <img src="../assets/images/图层 10.png"
                     alt />
                <span class="span2">{{card.mobile}}</span>
              </div>
            </div>
            <img :src="card.companyLogo"
                 alt
                 class="company_img" />
          </div>

        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import mixin from "../utils/share";
import ladingPage from "../utils/ladingPage";
import qrcode from "@/components/qrcode";
import bindTel from "./bind.vue";
export default {
  mixins: [mixin, ladingPage],
  components: { qrcode, bindTel, },
  props: {},
  data () {
    return {
      defaultHead: require('../assets/images/头像.png'),
      actions: [
        { text: '转发' },
        { text: '删除' }
      ],
      showPopover: [],
      activeScope: 0,
      dialogshow: false,
      showSwitchCards: false,
      showCards: false,
      currCardIndex: 0,
      showStatistic: true,
      showUser: true,
      showShare: false,
      qrshow: false,
      qrcode: "",
      form2: {
        businessCard: {},
        ad: {},
        article: {},
      },
      show2: false,
      url: window.location.href,
      show: false,
      myCards: [],
      favoriteCards: [],
      visitorCards: [],
      myStatistics: {}
    };
  },
  computed: {},
  created () {

  },
  mounted () {
    this.getMyCards()
    this.getFavoriteCards()
    this.changeScope(0)
    this.getvisitorCards()
  },
  watch: {},
  methods: {
    showCardOption (i) {
      this.$set(this.showPopover, i, true)
    },
    onSelect (val, card) {
      if (val.text === '转发') {
        window.location.href = window.location.origin + window.location.pathname + `#/card?tenantId=${localStorage.getItem("tenantId")}&id=${card.businessCardId}&share=true`
      } else if (val.text === '删除') {
        this.$api
          .post('/api/crm/businessCardFavorite/del', { id: card.id })
          .then((res) => {
            if (res.code === 'C0000') {
              this.$toast({
                message: "删除成功",
                className: "_toast"
              })
              this.getFavoriteCards()
            }
          })
      }
    },
    authentication () {
      if (!this.mobile) {
        this.dialogshow = true;
      } else {
        this.$toast("已成功绑定");
      }
    },
    getMyCards () {
      this.$api
        .post('/api/crm/businessCard/getBusinessCardByOpenId', {
          openId: this.$cookies.get('openId'),
        })
        .then((res) => {
          if (res.code === 'C0000') {
            if (res.result && res.result.length) {
              this.myCards = res.result
              let ind = this.myCards.findIndex(item => item.id == localStorage.getItem("businessCardId"))
              this.currCardIndex = ind > -1 ? ind : 0
            }
          }
        })
    },
    viewCard (id) {
      window.location.href = window.location.origin + window.location.pathname + `#/card?tenantId=${localStorage.getItem("tenantId")}&id=${id}`
    },
    toUserCard (item) {
      if (item.role === '企业用户' && item.wechatUserBusinessCardId) {
        window.location.href = window.location.origin + window.location.pathname + `#/card?tenantId=${localStorage.getItem("tenantId")}&id=${item.wechatUserBusinessCardId}`
      }
    },
    getFavoriteCards () {
      this.$api
        .post('/api/crm/businessCardFavorite/list', {
          data: {
            openId: this.$cookies.get('openId'),
          }
        })
        .then((res) => {
          if (res.code === 'C0000') {
            if (res.result && res.result.list) {
              this.favoriteCards = res.result.list
              // this.showPopover = []
              // this.favoriteCards.forEach(item => {
              //   this.showPopover.push(false)
              // })

            }
          }
        })
    },
    getvisitorCards () {
      this.$api
        .post('/api/crm/accessLog/recentLog', {
          pageSize: 100,
          pageNum: 1,
          orderByDesc: 'createTime',
          data: {
            openId: this.$cookies.get('openId'),
          },
          param: {
            action$IN: [3]
            // action$IN: [1, 2, 3]
          }
        })
        .then((res) => {
          if (res.code === 'C0000') {
            if (res.result && res.result.list) {
              this.visitorCards = res.result.list
            }
          }
        })
    },
    changeScope (i) {
      this.activeScope = i

      let dataScope = {}
      let now = new Date()
      let year = now.getFullYear()
      let month = now.getMonth() + 1
      let date = now.getDate()
      if (i === 0) { // 今天
        dataScope.beginDate = year + (month < 10 ? '-0' + month : '-' + month) + (date < 10 ? '-0' + date : '-' + date)
        dataScope.endDate = year + (month < 10 ? '-0' + month : '-' + month) + (date < 10 ? '-0' + date : '-' + date)
      } else if (i === 1) { // 本月
        dataScope.beginDate = year + (month < 10 ? '-0' + month : '-' + month) + '-01'
        let day = new Date(year, month, 0).getDate()
        dataScope.endDate = year + (month < 10 ? '-0' + month : '-' + month) + (day < 10 ? '-0' + day : '-' + day)
      } else if (i === 2) { // 今年
        dataScope.beginDate = year + '-01-01'
        dataScope.endDate = year + '-12-31'
      }

      this.getArticleStatistics(dataScope)
    },
    getArticleStatistics (dataScope) {
      this.$api
        .post('/api/crm/dw/accessLog/getArticleStatisticsByCard', {
          data: {
            openId: this.$cookies.get('openId'),
            ...dataScope
          }
        })
        .then((res) => {
          if (res.list) {
            this.myStatistics = res.list[0] || {}
          }
        })
    },
    tel (value) {
      window.location.href = `tel:${value}`;
      this.Action(8);
    }
  }
}
</script>

<style scoped lang="less">
.no_data {
  color: #888;
  text-align: center;
  padding: 10px;
}
.top_bg {
  width: 100%;
  // height: 516px;
  background: url("../assets/images/组 1.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 100px 30px 20px;
  box-sizing: border-box;
  .options {
    width: 100%;
    // height: 280px;
    // padding: 80px 0;
    box-sizing: border-box;
    .title {
      color: #fff;
      font-weight: bold;
      font-size: 38px;
      letter-spacing: 2px;
      text-align: center;
    }
    .option_btn {
      display: flex;
      justify-content: center;
      margin: 60px 0;
      div {
        padding: 20px 50px;
        background: #fff;
        margin: 20px 30px;
        border-radius: 40px;
        font-size: 30px;
      }
    }

    .cert {
      color: #3874f6;
    }
    .trail {
      color: #f25023;
    }
    .manager_card {
      background: #f2f6fc;
      padding: 20px;
      font-weight: bold;
      color: #303133;
      border-radius: 10px;
      margin-top: 120px;
      // line-height: 60px;
      .manager_pic {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        vertical-align: -0.95em;
      }
      span {
        color: #3874f6;
        padding: 0 8px;
      }
    }
  }
}
.my_card {
  width: 100%;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0 2px 12px 0 rgb(200, 200, 200, 0.01);
  .card_item {
    border-bottom: none !important;
  }
  .switch {
    position: absolute;
    right: 0;
    bottom: 0;
    background: linear-gradient(to left, #2276c5, #25bcd6);
    border-radius: 20px 0 20px 0;
    padding: 10px;
    color: #fff;
    font-size: 24px;
    width: 40px;
    text-align: center;
    .van-icon {
      font-size: 30px;
    }
  }
}
.my_card_list {
  padding: 20px;
  box-sizing: border-box;
}
.popover_box {
  height: 20px;
  .van-popover__wrapper {
    font-size: 32px;
  }
}
.my_card_list,
.card_list,
.my_card {
  .active_card {
    border: 1px solid #2276c5 !important;
    border-radius: 10px;
  }
  .card_item {
    border-bottom: 2px solid #f5fafd;
    padding: 20px;
    .head {
      display: flex;
      margin-bottom: 30px;
      .head_img {
        width: 98px;
        height: 98px;
        border-radius: 50%;
      }
      .cert_icon {
        height: 40px;
        vertical-align: -0.2em;
      }
      .company_img {
        width: 100px;
        height: 100px;
      }
      .pp {
        flex: 1;
        padding-left: 30px;
        line-height: 30px;
        padding-top: 10px;
        .p1 {
          font-size: 37px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000001;
        }
        .p2 {
          font-size: 25px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #4a4a4a;
          padding-top: 16px;
        }
        .p3 {
          font-size: 25px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #4a4a4a;
          padding-top: 10px;
        }
      }
    }
    .item_info {
      display: flex;
      align-items: center;
      margin-top: 20px;
      img {
        width: 28px;
        height: 28px;
        margin-right: 12px;
      }
      .span2 {
        font-size: 25px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #4a4a4a;
        flex: 1;
        padding-right: 30px;
      }
      .span3 {
        width: 77px;
        height: 27px;
        background: #ffffff;
        border: 1px solid #ff6e03;
        border-radius: 14px;
        text-align: center;
        line-height: 34px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff6e03;
        margin-right: 28px;
      }
    }
  }
}
.mine_content {
  background: #f5fafd;
  // height: 100%;
  // margin-top: -120px;
  padding: 20px;
  border-radius: 20px;
  margin-top: 20px;
  box-sizing: border-box;
  .tabs {
    display: flex;
    justify-content: space-between;
    .tab_item {
      width: 34%;
      text-align: center;
      background: #f5fafd;
      height: 62px;
      line-height: 70px;
      border-radius: 10px 10px 0 0;
    }
    .tab_active {
      background: #2276c5;
      color: #fff;
    }
  }
  .box {
    background: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .content_name {
    font-size: 28px;
    font-weight: bold;
    padding: 26px 20px;
    border-bottom: 2px solid #f5fafd;
    .van-icon {
      float: right;
    }
    .total_number {
      color: #f56c6c;
      background: #fef0f0;
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
    }
  }

  .statistic {
    display: flex;
    justify-content: space-between;
    padding: 40px 20px;
    .statistic_item {
      text-align: center;
      .number {
        font-size: 36px;
        font-weight: bold;
        color: #f25023;
      }
    }
  }
  .user_list {
    .user_item {
      border-bottom: 2px solid #f5fafd;
      padding: 20px;
      .cert_icon {
        height: 26px;
        vertical-align: -0.2em;
      }
      .head {
        display: flex;
        .head_img {
          width: 98px;
          height: 98px;
          border-radius: 50%;
        }
        .company_img {
          width: 100px;
          height: 100px;
        }
        .pp {
          flex: 1;
          padding-left: 30px;
          line-height: 30px;
          padding-top: 10px;
          .p1 {
            font-size: 32px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000001;
            .date {
              float: right;
              font-size: 20px;
              color: #888;
              font-weight: normal;
            }
          }
          .p2 {
            font-size: 25px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #4a4a4a;
            padding-top: 16px;
          }
          .p3 {
            font-size: 25px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #4a4a4a;
            padding-top: 10px;
          }
        }
      }
    }
  }
}

.wrapper {
  position: relative;
}
.img_img {
  width: 70%;
  display: block;
  height: auto;
  margin: 0 auto;
  margin-top: 60px;
}
.close {
  width: 72px;
  height: 72px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -100px;
}
.share_img {
  width: 320px;
  height: 290px;
  margin-top: 20px;
  margin-left: 320px;
}
</style>
